<template>
  <div class="container">
    <div class="section-header">
      <div class="section-header-start">
        <h3 class="title">{{ $t('company hiring now') }}</h3>
      </div>
    </div>
    <div v-if="isLoading" class="loading-container">
      <div class="loading-item"></div>
      <div class="loading-item"></div>
      <div class="loading-item"></div>
    </div>
    <div class="swiper-container is-hidden-mobile" v-else>
      <client-only>
        <Swiper
          class="swiper"
          :loop="false"
          :autoplay="{
            delay: 10000
          }"
          navigation
          :breakpoints="swiperBreakpoint"
        >
          <SwiperSlide v-for="(i, index) in companies" :key="index">
            <CompanyCard :data="i" />
          </SwiperSlide>
        </Swiper>
      </client-only>
    </div>
    <div class="lists is-mobile-only">
      <CompanyCard v-for="(i, index) in companies" :key="index" :data="i" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '~/store/auth'
import useFetchCustom from '@/services/global-useFetch'
import CompanyCard from '../reuse/CompanyCard.vue'

// swiperBreakpoint
const swiperBreakpoint = {
  320: { slidesPerView: 1, spaceBetween: 8 },
  760: { slidesPerView: 3, spaceBetween: 16 },
  1024: { slidesPerView: 4, spaceBetween: 16 }
}
// set variable
const store = useAuthStore()
const companies = ref<any[]>([])
const allFeatureCP = ref<any[]>([])
const companyName = ref<any>('')
const industry = ref<any>([])
const page = ref<any>(1)
const perPage = ref<any>(10)
const isLoading = ref<any>(false)

// Accessing the `urlImage` getter
const getUrlImage = useNuxtApp().$urlImage

// fetch

// fetchCompany
const fetchCompanies = async () => {
  try {
    isLoading.value = true
    const body = {
      companyName: companyName.value || '',
      companySizeId: [],
      location: [],
      industryId: industry.value || [],
      page: page.value,
      perPage: perPage.value,
      searchType: 'Hiring',
      token: store.token
    }
    const { data, error } = await useFetchCustom('/get-employer-search-web')
      .post(body)
      .json()
    if (data.value) {
      const res: any = data.value
      companies.value = res.employerList
    }
  } catch (e) {
    throw e
  } finally {
    isLoading.value = false
  }
}
const getCover = async () => {
  try {
    const { data, error } = await useFetchCustom(
      '/get-feature-employer-list-web'
    )
      .post()
      .json()
    if (data.value) {
      const res: any = data.value
      allFeatureCP.value = res.company
      let img = getUrlImage + res.featureProfile
    }
  } catch (e) {
    throw e
  }
}

// fetching
fetchCompanies()
getCover()
</script>

<style scoped lang="scss">
@media (max-width: 769px) {
  .lists {
    display: flex !important;
  }
}
.lists {
  gap: 1rem;
  overflow-y: auto !important;
  display: none;

  .company-card {
    min-width: 70%;
    background-color: #fff;
  }
}
</style>
