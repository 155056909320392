<template>
  <section class="homepage-section" v-if="banNer">
    <div class="container">
      <div class="loading-container topBanner-loading" v-if="isLoading">
        <div class="loading-item"></div>
      </div>

      <div class="swiper-container spotlight-banner no-padding" v-else>
        <client-only>
          <Swiper
            class="swiper"
            ref="mySwipy"
            @click="clickBanner"
            @slide-change="viewBanner"
            :modules="[
              SwiperAutoplay,
              SwiperEffectCreative,
              SwiperNavigation,
              SwiperPagination
            ]"
            :slides-per-view="1"
            :loop="false"
            navigation
            :pagination="{
              clickable: true,
              el: '.swiper-pagination'
            }"
            :autoplay="{
              delay: 10000,
              disableOnInteraction: true
            }"
          >
            <SwiperSlide
              tag="div"
              class="spotlight-banner"
              v-for="(b, index) in banNer"
              :key="index"
            >
              <a :href="b.url" target="_blank">
                <img
                  :src="b.image.src"
                  alt="cover"
                  style="cursor: pointer"
                  rel="preload"
                />
              </a>
            </SwiperSlide>
            <div class="swiper-pagination"></div>
          </Swiper>
        </client-only>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import useFetchCustom from '@/services/global-useFetch'
// data
const banNer = ref<any>([])
const realIndex = ref<any>(0)
const isLoading = ref<boolean>(false)
const viewedIndexes: Set<number> = new Set()
const viewBanner = async (slideData: any) => {
  const index = slideData.realIndex // set index from slide
  if (!viewedIndexes.has(index)) {
    const idx = banNer.value[index]._id // findIndex id
    try {
      const body = {
        bannerId: idx,
        type: 'View'
      }
      const { data } = await useFetchCustom('/banner-view-web').post(body)
    } catch (error) {
      console.error('Error:', error) //TODO: remove when production
    }
    viewedIndexes.add(index)
  }
}

// fetch setup
const fetchTopBanner = async () => {
  try {
    isLoading.value = true
    // const res: any = await $fetch(`${config.public.baseApi}/banner-slide-web`, {
    //   method: 'POST',
    //   body: {
    //     bannerType: 'Spotlight',
    //     deviceType: 'web'
    //   }
    // })
    const body = {
      bannerType: 'Spotlight',
      deviceType: 'web'
    }
    const { data } = await useFetchCustom('/banner-slide-web').post(body)
    if (data.value) {
      const res: any = data.value
      banNer.value = res.banner
      realIndex.value = 0
    }
    // console.log('data topBanner', res)
    isLoading.value = false
  } catch (e) {
    throw e
  }
}
// click function
const clickBanner = async (slideData: any) => {
  try {
    const curId = banNer.value[slideData.realIndex]._id
    const addClick = {
      bannerId: curId
    }
    const { data } = await useFetchCustom('/banner-click-web').post(addClick)
  } catch (e) {
    throw e
  }
}

// fetching
fetchTopBanner()
</script>

<style lang="scss">
.spotlight-banner {
  img {
    width: 100%;
    height: auto;
    display: block;
    aspect-ratio: 5/1;
    object-fit: cover;
    border-radius: 10px;
  }
}

.topBanner-loading {
  width: 100%;
  // height: 500px;
  aspect-ratio: 16/6;
  .loading-item {
    width: 100%;
  }
}
</style>
