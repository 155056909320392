<template>
  <div class="container">
    <div class="section-header" v-if="featureEm.length">
      <div class="section-header-start">
        <h2 class="title">Sponsored companies</h2>
      </div>
    </div>
    <div class="loading-container" v-if="isLoading">
      <div class="loading-item"></div>
    </div>
    <div class="swiper-container no-padding has-shadow no-navigation" v-else>
      <client-only>
        <Swiper
          class="swiper"
          :modules="[SwiperAutoplay, SwiperEffectCreative, SwiperNavigation]"
          :slides-per-view="1"
          :loop="false"
          navigation
          :autoplay="{
            delay: 10000,
            disableOnInteraction: true
          }"
        >
          <SwiperSlide v-for="(i, index) in featureEm" :key="index">
            <nuxt-link
              target="_blank"
              :to="'featured-company/' + i._id"
              class="featured-employer-card"
            >
              <div class="featured-employer-card-cover">
                <img
                  :src="getUrlImage + i.featureProfile"
                  alt="cover"
                  loading="lazy"
                />
              </div>
              <div
                :style="{ backgroundColor: i.companyColor }"
                class="featured-employer-card-body"
              >
                <div class="detail">
                  <div class="logo">
                    <img
                      :src="getUrlImage + i.logo"
                      alt="logo"
                      loading="lazy"
                    />
                  </div>
                  <div class="text">
                    <h3
                      :style="{
                        color: i.textColor
                      }"
                    >
                      {{ i.companyName }}
                    </h3>
                    <p
                      :style="{
                        color: i.textColor
                      }"
                    >
                      {{ i.industry }}
                    </p>
                  </div>
                </div>

                <div class="buttons">
                  <button
                    class="button"
                    :style="{
                      backgroundColor: i.companyColor,
                      color: i.textColor,
                      borderColor: i.textColor
                    }"
                  >
                    {{ $t('view more') }}
                  </button>
                </div>
              </div>
            </nuxt-link>
          </SwiperSlide>
        </Swiper>
      </client-only>
    </div>
  </div>
</template>

<script setup lang="ts">
import useFetchCustom from '@/services/global-useFetch'

const featureEm = ref<any>([])
const isLoading = ref<any>(false)
// get image
const getUrlImage = useNuxtApp().$urlImage
//
const fethFeatureEm = async () => {
  try {
    isLoading.value = true
    const { data, error } = await useFetchCustom(
      '/get-feature-employer-list-web'
    )
      .post()
      .json()
    if (data.value) {
      const res: any = data.value
      featureEm.value = res.company
    }
  } catch (e) {
    console.error(e)
  } finally {
    isLoading.value = false
  }
}

// start FC
fethFeatureEm()
</script>
<style lang="scss">
.featured-employer-card {
  .featured-employer-card-cover {
    img {
      border-radius: var(--round-md) var(--round-md) 0 0;
      width: 100%;
      display: block;
      aspect-ratio: 16/6;
      transition: all 0.3s ease-in-out;
      object-fit: cover;
    }
  }

  @media (max-width: 769px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .featured-employer-card-body {
    display: flex;
    align-items: center;
    padding: 1.25rem;
    border-radius: 0 0 var(--round-md) var(--round-md);
    justify-content: space-between;
    .detail {
      display: flex;
      gap: 1rem;
      align-items: center;
      @media (max-width: 769px) {
        .logo {
          min-width: 50px;
          min-height: 50px;
          max-width: 50px;
          max-height: 50px;
        }
      }
      .logo {
        min-width: 70px;
        min-height: 70px;
        max-width: 70px;
        max-height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 6px;
        @media (max-width: 769px) {
          img {
            min-width: 40px;
            min-height: 40px;
            max-width: 40px;
            max-height: 40px;
          }
        }
        img {
          display: block;
          min-width: 55px;
          min-height: 55px;
          max-width: 55px;
          max-height: 55px;
          object-fit: contain;
        }
      }

      .text {
        color: #fff;
        @media (max-width: 769px) {
          line-height: 1.3;
        }
        h3 {
          font-weight: 700;
          font-size: var(--md-font);
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
        }
        p {
          font-size: var(--sm-font);
        }
      }
    }
    @media (max-width: 769px) {
      .buttons {
        width: 100%;
        display: flex;
        .button {
          padding-bottom: calc(0.05em - 1px);
          padding-left: 1.25em;
          padding-right: 1.25em;
          padding-top: calc(0.05em - 1px);
          margin-top: 1rem;
          border-width: 1px;
          font-size: var(--sm-font);
        }
      }
    }
    .buttons {
      display: flex;
      button {
        font-family: var(--font-family);
        width: 100%;
        flex-grow: 1;
        border-width: 1.5px;
        font-weight: 700;
        font-size: var(--sm-font);
        padding-left: 1.75em;
        padding-right: 1.75em;
      }
    }
  }
}
</style>
